.root-login{
    background-image: url(./../../component/img/background.jpg);
    height: 100vh;
}

.formPanel{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 20%;
    background-color: white;
    padding: 20px;
    height: 200px;
    box-shadow: -4px 4px 3px 0px rgba(0,0,0,0.55);
}

.inputPanel{
    margin-top:auto;
    margin-bottom: auto;
    display:flex;
    flex-direction: column;
}


  